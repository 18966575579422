.thankyou-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.thankyou-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thankyou-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 3rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}