/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.main {
  @apply font-calligraphic font-bold;
}

.top-background {
  background-image: url('/public/flowers-bg.png');
  background-size: cover;
  background-position: center;
  height: 300px;
  position: relative;
  z-index: -1;
  filter: blur(4px);
  opacity: 0.5;
}

.timeline-line {
  width: 2px;
  height: calc(100% - 350px);
  top: 350px;
  background-color: #d1d5db;
  z-index: -1;
}


.flower-transparent {
  opacity: 0.8; /* Adjust the value as needed for desired transparency */
}

.dark-golden-border {
  border: 4px solid rgb(210, 187, 84) /* Dark golden color */
}

.thick-dark-golden-border {
  border: 6px solid rgb(210, 187, 84); /* Dark golden color */
}